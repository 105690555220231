import { useEffect, useState } from 'react';
import { ReleaseDto, ReleasesService } from '../../services/release.service';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Assure-toi que le CSS de react-datepicker est importé
import ReactQuill from 'react-quill';  // Import de React Quill
import 'react-quill/dist/quill.snow.css';  // Importer les styles de l'éditeur Quill

type Props = {
  releaseId?: string;
  onClose: () => void;
};

const ReleaseForm: React.FC<Props> = ({ releaseId, onClose }) => {
  const [release, setRelease] = useState<Partial<ReleaseDto>>({
    description: '',
    lang: '',
    title: '',
    shortDescription: '',
    publishDate: '',
    triggersNotif: false,
    id: releaseId
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);


  useEffect(() => {
    if (releaseId) {
      fetchRelease(releaseId);
    }
  }, [releaseId]);

//   useEffect(() => {
//     console.log('new release: ' + release.description); // Log dès que `release` est mis à jour
//   }, [release]); // Se déclenche lorsque `release` change

const fetchRelease = async (id: string) => {
    setLoading(true);
    try {
      const response = await ReleasesService.getRelease(id);      
    setRelease(response.data);
      
    } catch (error) {
      console.error('Failed to fetch release:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (releaseId) {
        // Update release with JSON payload
        await ReleasesService.updateRelease(releaseId, release);
      } else {
        // Create release with FormData
        const formData = new FormData();
        formData.append('title', release.title || '');
        formData.append('description', release.description || '');
        formData.append('lang', release.lang || '');
        formData.append('publishDate', release.publishDate || '');
        formData.append('triggersNotif', String(release.triggersNotif || false));
        formData.append('shortDescription', release.shortDescription || '');

        if (file) {
            formData.append('file', file);
        }

        await ReleasesService.createRelease(formData);
      }
      onClose();
    } catch (error) {
      console.error('Failed to save release:', error);
    } finally {
      setLoading(false);
    }
  };

  // Ajoute une vérification pour éviter un appel à setRelease inutile
  const handleChangeDescription = (value: string) => {
    if (release.description !== value) { // Vérifie si la valeur a changé avant de mettre à jour
      setRelease({ ...release, description: value });
    }
  };
  
  const handleChangeShortDescription = (value: string) => {
    if (release.shortDescription !== value) { // Vérifie si la valeur a changé avant de mettre à jour
      setRelease({ ...release, shortDescription: value });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 border rounded shadow-md " style={{ minWidth: '1000px' }}>
      <h2 className="text-xl font-bold mb-4">
        {releaseId ? 'Edit Release' : 'Create Release'}
      </h2>
      <div className="mb-2">
        <label className="block text-sm font-medium">Language</label>
        <select
            value={release.lang || ''}
            onChange={(e) => setRelease({ ...release, lang: e.target.value })}
            className="border border-gray-300 rounded px-2 py-1 w-full"
            required
        >
            <option value="" disabled>Select language</option>
            <option value="fr">Français</option>
            <option value="en">English</option>
        </select>
      </div>
      <div className="mb-2">
        <label className="block text-sm font-medium">Title</label>
        <input
          type="text"
          value={release.title || ''}
          onChange={(e) => setRelease({ ...release, title: e.target.value })}
          className="border border-gray-300 rounded px-2 py-1 w-full"
          required
        />
      </div>
      <div className="mb-2">
        <label className="block text-sm font-medium">Description</label>
        {(releaseId == null || !loading) && 
        <ReactQuill
          value={release.description || ''}
          onChange={handleChangeDescription}
          className="border border-gray-300 rounded px-2 py-1 w-full"
        />
        }
      </div>
      <div className="mb-2">
        <label className="block text-sm font-medium">Publication Date</label>
        {/* <DatePicker
          selected={release.publishDate ? new Date(release.publishDate) : null}
          onChange={(date: Date | null) => setRelease({ ...release, publishDate: date!.toDateString() })}
          className="border border-gray-300 rounded px-2 py-1 w-full"
          dateFormat="yyyy-MM-dd"
        /> */}
        <input
            type="date"
            value={release.publishDate ? release.publishDate.split('T')[0] : ''}
            onChange={(e) => setRelease({ ...release, publishDate: e.target.value + 'T00:00:00' })}
            className="border border-gray-300 rounded px-2 py-1 w-full"
        />    
      </div>
      <div className="mb-2">
        <label className="block text-sm font-medium">Short Description</label>
        {(releaseId == null || !loading) &&
        <ReactQuill
          value={release.shortDescription || ''}
          onChange={handleChangeShortDescription}
          className="border border-gray-300 rounded px-2 py-1 w-full"
        />
        }
      </div>
      <div className="mb-2 flex items-center">
        <input
          type="checkbox"
          checked={release.triggersNotif || false}
          onChange={(e) => setRelease({ ...release, triggersNotif: e.target.checked })}
          className="mr-2"
        />
        <label className="text-sm font-medium">Show Release Popup</label>
      </div>
      { !releaseId &&
      <div className="mb-2">
        <label className="block text-sm font-medium">Image</label>
        <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="border border-gray-300 rounded px-2 py-1 w-full"
        />
      </div>
      }
      {
        releaseId && !!release.mediaUrl &&
        <div className="mb-2">
            <img src={release.mediaUrl} alt="Media" className="h-auto rounded-md" style={{ width: '500px' }}/>
        </div>
      }
      <div className="flex justify-end mt-4">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-gray-300 rounded mr-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`px-4 py-2 rounded ${loading ? 'opacity-50' : ''}`}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  );
};

export default ReleaseForm;
