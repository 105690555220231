import { useEffect, useState } from 'react';
import { MinimalReleaseDto } from '../../services/release.service';
import { ReleasesService } from '../../services/release.service';
import delete_cross from '../../../assets/img/element_croix_rouge_supprim.png'
import modify_icon from '../../../assets/img/element_modification_grey_v2.png'
import ReleaseForm from './ReleaseForm';
import NewModal from '../components/custom-components/modal/newModal';
import './Releases.scss'

const Releases: React.FC = () => {
  const [releases, setReleases] = useState<MinimalReleaseDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedReleaseId, setSelectedReleaseId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const deleteRelease = async (releaseId: string) => {
    if (window.confirm('Are you sure you want to delete this release?')) {
      setLoading(true);
      try {
        await ReleasesService.deleteRelease(releaseId);
        setReleases(releases.filter(r => r.id !== releaseId));
      } catch (error) {
        console.error('Failed to delete release:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (id: string) => {
    setSelectedReleaseId(id);
    setOpenModal(true);
  };

  const handleCreate = () => {
    setSelectedReleaseId(null);
    setOpenModal(true);
  };

  const handleClose = () => {
    setSelectedReleaseId(null);
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchReleases = async () => {
      setLoading(true);
      try {
        const response = await ReleasesService.getReleases();
        setReleases(response.data.sort((a,b) => a.publishDate > b.publishDate ? -1 : 1));
      } catch (error) {
        console.error('Failed to fetch releases:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReleases();
  }, []);

  return (
    <div className="parameters-page p-4">
      <h1 className="text-2xl font-bold mb-4">Releases</h1>
      <button
        onClick={handleCreate}
        className="mb-4 px-4 py-2 rounded"
      >
        Créer une release
      </button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="releases-table min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 w-[5%]">Date de publication</th>
            <th className="border border-gray-300 px-4 py-2 w-[88%]">Titre</th>
            <th className="border border-gray-300 px-4 py-2 w-[5%]">Lang</th>
            <th className="border border-gray-300 px-4 py-2 w-[1%]"></th>
            <th className="border border-gray-300 px-4 py-2 w-[1%]"></th>
          </tr>
        </thead>
        <tbody>
          {releases.map((release) => (
            <tr key={release.id} className="hover:bg-gray-50">
              <td className="border border-gray-300 px-4 py-2 w-[5%]">
                {(new Date(release.publishDate)).toLocaleDateString('fr-FR')}
              </td>
              <td className="border border-gray-300 px-4 py-2 w-[88%]">{release.title}</td>
              <td className="border border-gray-300 px-4 py-2 w-[5%]">{release.lang}</td>
              <td className="border border-gray-300 py-2 w-[1%]">
                <button>
                  <img src={modify_icon} alt="modify" width="24" height="24" onClick={() => handleEdit(release.id)} />
                </button>
              </td>
              <td className="border border-gray-300 py-2 w-[1%]">
                <button>
                  <img src={delete_cross} alt="delete" width="24" height="24" onClick={() => deleteRelease(release.id)} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      )}
      <NewModal isOpen={openModal} onClose={handleClose}>
        <ReleaseForm releaseId={selectedReleaseId!} onClose={handleClose} />
      </NewModal>
    </div>
  );
};

export default Releases;
