import React, { useState, useEffect } from 'react';
import './Modules-Access.scss';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import ConsoleAdminService from '../../../services/console-admin.service';
import { Trans } from 'react-i18next';
import Loading from '../custom-components/loading/loading';
import SaveButton from '../custom-components/saveButton/SaveButton';
import NotificationSnackbar from '../custom-components/notificationSnackbar/NotificationSnackbar';

interface ModulesAccessType {
  [key: string]: boolean;
}

interface Module {
  key?: string;
  label: string;
  modules?: Module[];
}

interface ModulesAccessProps {
  clientId: string;
}

const moduleGroups: Module[] = [
  {
    label: 'Réseau',
    modules: [
      { key: 'ADMIN_POI', label: 'Administration des POIs' },
      { key: 'ADMIN_POI_GROUP', label: 'Gestion des groupes' },
      { key: 'ADMIN_REGION', label: 'Groupes régionaux (Groupes structurels)' },
      { key: 'BULK_UPDATE', label: 'Modification groupée' },
    ],
  },
  {
    label: 'Google',
    modules: [
      {
        key: 'GMB',
        label: 'Google My Business',
        modules: [
          { key: 'GMB_INSIGHT', label: 'Performances' },
          {
            key: 'GMB_REVIEWS',
            label: 'Avis Clients et Statistiques',
            modules: [
              { key: 'GMB_REVIEWS_AI', label: 'Suggestions des réponses par l’IA' },
              { key: 'GMB_REVIEWS_SUMMARY', label: 'Météo des avis par l’IA' },
            ],
          },
          { key: 'GMB_POST', label: 'Google Posts' },
          { key: 'GMB_REVIEWS_BENCHMARK', label: 'Radar Concurrence' },
          { key: 'POWER_REVIEW', label: 'Power Review' },
        ],
      },
    ],
  },
  {
    label: 'Store Locator',
    modules: [
      {
        key: 'STORE_LOCATOR',
        label: 'Store Locator',
        modules: [
          { key: 'CAMPAIGN', label: 'Campagnes SL' },
          { key: 'ADMIN_GEO', label: 'Administration Géographique (Pages Villes)' },
        ],
      },
    ],
  },
  {
    label: 'Divers',
    modules: [
      { key: 'POI_VIEWER', label: 'Sélecteur des POIs' },
      { key: 'PRESENCE_MANAGEMENT', label: 'Presence Management' },
      { key: 'MEDIA_LIBRARY', label: 'Bibliothèque des médias' },
      { key: 'SOCIAL_NETWORKS', label: 'Réseaux Sociaux (Apibots)' },
      { key: 'AI_CONTENT', label: 'Génération de contenu par IA' },
    ],
  },
];

const ModulesAccess: React.FC<ModulesAccessProps> = ({ clientId = '' }) => {
  const [modulesAccess, setModulesAccess] = useState<ModulesAccessType>({});
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const getAllModuleKeys = (modules: Module[]): string[] => {
    let keys: string[] = [];
    modules.forEach((module) => {
      if (module.key) {
        keys.push(module.key);
      }
      if (module.modules) {
        keys = keys.concat(getAllModuleKeys(module.modules));
      }
    });
    return keys;
  };

  const init = async () => {
    try {
      if (clientId !== '') {
        const response = await ConsoleAdminService.getModuleAccess(clientId);
        const data = response.data.props as ModulesAccessType;
        if (data.BLOCKED === undefined) {
          data.BLOCKED = false;
        }
        const allModuleKeys = getAllModuleKeys(moduleGroups);
        allModuleKeys.forEach((key) => {
          if (data[key] === undefined) {
            data[key] = false;
          }
        });
        setModulesAccess(data);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage('Échec de récupération des Modules Access');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    init();
  }, [clientId]);

  const findModuleByKey = (key: string, modules: Module[]): Module | null => {
    for (let module of modules) {
      if (module.key === key) {
        return module;
      }
      if (module.modules) {
        const found = findModuleByKey(key, module.modules);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const getAllChildKeys = (module: Module): string[] => {
    let keys: string[] = [];
    if (module.modules) {
      module.modules.forEach((subModule) => {
        if (subModule.key) {
          keys.push(subModule.key);
        }
        keys = keys.concat(getAllChildKeys(subModule));
      });
    }
    return keys;
  };

  const updateModulesAccess = (key: string, checked: boolean) => {
    setModulesAccess((prevState) => {
      const newState = { ...prevState, [key]: checked };
      return newState;
    });
  };

  const handleChange = (event: { target: { name: string; checked: boolean } }) => {
    const { name, checked } = event.target;
    updateModulesAccess(name, checked);
  };

  const handleSave = async () => {
    try {
      const data = JSON.parse(JSON.stringify(modulesAccess));
      const response = await ConsoleAdminService.updateModuleAccess(clientId, data);
      if (response.status !== 200) {
        throw new Error('Failed to update modules access');
      }
      setSnackbarMessage('parameters.moduleaccess.messageSnackbar.succesToUpdate');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update Modules Access', error);
      setSnackbarMessage('parameters.moduleaccess.messageSnackbar.failedToUpdate');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  const renderModules = (modules: Module[], parentChecked = true, indentLevel = 0) => {
    return modules.map((module) => {
      const isChecked = modulesAccess[module.key || ''] || false;
      const hasSubmodules = module.modules && module.modules.length > 0;
  
      return (
        <div key={module.key || module.label} className="module-item">
          {module.key ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleChange}
                  name={module.key}
                  style={{
                    color: isChecked ? '#15afad' : '',
                  }}
                />
              }
              label={<Trans>{module.label}</Trans>}
            />
          ) : (
            <h4>{module.label}</h4>
          )}
          {hasSubmodules && isChecked && renderModules(module.modules!, isChecked, indentLevel + 1)}
        </div>
      );
    });
  };  
  
  return (
    <div className="modules-access-page">
      <div className="module-groups">
        {moduleGroups.map((group) => (
          <div key={group.label} className="module-group">
            <h3>{group.label}</h3>
            {group.modules && renderModules(group.modules)}
          </div>
        ))}
      </div>
      {modulesAccess.BLOCKED !== undefined && (
        <div style={{ marginLeft: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={modulesAccess['BLOCKED']}
                onChange={handleChange}
                name="BLOCKED"
                style={{ color: modulesAccess['BLOCKED'] ? 'red' : '' }}
              />
            }
            label={<Trans>parameters.moduleaccess.BLOCKED</Trans>}
          />
        </div>
      )}
      <SaveButton onClick={handleSave} />
      <NotificationSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
      />
    </div>
  );
  
};

export default ModulesAccess;
