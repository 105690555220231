import { AxiosResponse } from 'axios';
import http from '../../http-common';
import TokenService from './token.service';

export interface MinimalReleaseDto {
    id: string;
    title: string;
    publishDate: string;
    lang: string;
}

export interface ReleaseDto {
    id: string;
    title: string;
    description: string;
    shortDescription: string;
    mediaUrl?: string;
    publishDate: string;
    lang: string;
    mediaName?: string;
    triggersNotif: boolean;
}

export class ReleasesService {
    public static getReleases(): Promise<AxiosResponse<MinimalReleaseDto[]>> {
        return http.get('/api/releases', { headers: TokenService.getAuthHeader() });
    }

    public static getRelease(releaseId: string): Promise<AxiosResponse<ReleaseDto>> {
        return http.get(`/api/releases/${releaseId}`, { headers: TokenService.getAuthHeader() });
    }

    public static createRelease(data: FormData): Promise<AxiosResponse<any>> {
        return http.post('/api/releases', data, {
            headers: {
                ...TokenService.getAuthHeader(),
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    public static updateRelease(releaseId: string, data: Record<string, any>): Promise<AxiosResponse<any>> {
        return http.put(`/api/releases/${releaseId}`, data, { headers: TokenService.getAuthHeader() });
    }

    public static deleteRelease(releaseId: string): Promise<AxiosResponse<any>> {
        return http.delete(`/api/releases/${releaseId}`, { headers: TokenService.getAuthHeader() });
    }
}

// export default new ReleasesService();
