import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const NewModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <React.Fragment>
            <div className="backdrop" />
            <div className="wrapper">        {/* Fond noir semi-transparent */}
  
                {/* Conteneur centré */}
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg relative">
                        {/* Bouton de fermeture */}
                        <button
                        onClick={onClose}
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                        &times;
                        </button>
            
                        {/* Contenu de la modale */}
                        {children}
                    </div>
                </div>
            </div>
      </React.Fragment>,
      document.body
    );
  };

export default NewModal;
